import { useEffect } from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./../static/css/main.css";
import Navbar from "./snippets/Main/Navbar";
import Formulario from "./snippets/Main/Formulario";
import Box from "./snippets/Main/Box";
import Slide from "react-reveal/Slide";
import { Fade } from "react-reveal";

function Main({
  user,
  setUser,
  removeCookie,
  axios_instance,
  request,
  toast,
  baseURL,
}) {
  const [pActive, setPActive] = useState(
    user != null ? user.rol.menu[0] : null
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [data, setData] = useState([]);
  const [menuA, setMenuA] = useState(false);
  const [fields, setFields] = useState([]);
  const [editableFields, setEditableFields] = useState([]);

  useEffect(() => {
    console.log(menuA);
  }, [menuA]);

  useEffect(() => {
    if (pActive != null) {
      const params = new URLSearchParams();
      params.append(
        "public_key",
        "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
      );
      params.append(
        "private_key",
        "+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
      );
      request(
        "post",
        `${pActive.aplicacion.path}/get`,
        (response) => {
          if (response.status == 200) {
            setData(response.data.data);
            var array = [];
            var editable_array = [];
            for (let i in response.data.form.fields) {
              if (i % 2 == 0 || response.data.form.fields[i].type == "edit") {
                var subarray = [];
                subarray.push(response.data.form.fields[i]);
                array.push(subarray);
              } else {
                var subarray = array[array.length - 1];
                subarray.push(response.data.form.fields[i]);
                //array.push(subarray)
              }
            }
            if (response.data.editable_form)
              for (let i in response.data.editable_form.fields) {
                if (
                  i % 2 == 0 ||
                  response.data.editable_form.fields[i].type == "edit"
                ) {
                  var subarray = [];
                  subarray.push(response.data.editable_form.fields[i]);
                  editable_array.push(subarray);
                } else {
                  var subarray = editable_array[editable_array.length - 1];
                  subarray.push(response.data.editable_form.fields[i]);
                  //array.push(subarray)
                }
              }
            setFields(array);
            setEditableFields(editable_array);
          }
        },
        (error) => {
          toast.error(error, { toastId: error, theme: "dark" });
        },
        params,
        `Obteniendo datos de ${pActive.name}`,
        true
      );
    }
  }, [pActive]);
  if (!user) return <Navigate to="/login" />;
  const Agregar = () => {
    return (
      <Formulario
        pActive={pActive}
        fields={fields}
        request={request}
        baseURL={baseURL}
        data={data}
        setData={setData}
        title={"Agregar"}
        action={"/create"}
        setEditableFields={setEditableFields}
        setFields={setFields}
        windowDimensions={windowDimensions}
      />
    );
  };

  const Editar = () => {
    return (
      <Formulario
        pActive={pActive}
        fields={editableFields}
        request={request}
        baseURL={baseURL}
        data={data}
        setData={setData}
        title={"Editar"}
        action={"/update"}
        setEditableFields={setEditableFields}
        setFields={setFields}
        windowDimensions={windowDimensions}
      />
    );
  };

  function toggle_menu() {
    setMenuA(!menuA);
  }

  //console.log(Object.values(data[0]));
  return (
    <div className="container row center">
      <Slide left when={menuA || windowDimensions.width >= 1000}>
        <div
          className={`row h100vh mxw300px ${menuA || windowDimensions.width>=1000 ? "active z1" : "inactive"} ${
            windowDimensions.width >= 1000 ? "p30_l" : "p10 absolute l0"
          }`}
        >
          <Navbar
            user={user}
            pActive={pActive}
            setPActive={setPActive}
            windowDimensions={windowDimensions}
            toggle_menu={toggle_menu}
          />
        </div>
      </Slide>
      {menuA || windowDimensions.width >= 1000 ? "" : ""}

      {menuA && windowDimensions.width < 1000 ? (
        <div className="block" onClick={toggle_menu}></div>
      ) : (
        ""
      )}
      <Fade bottom>
        <div className="row left content wrap">
          {windowDimensions.width < 1000 ? (
            <div className="menu open" onClick={toggle_menu}>
              <Icon icon="clarity:menu-line" style={{ fontSize: 30 }} />
            </div>
          ) : (
            ""
          )}
          <h1 className="title dark">{pActive.name}</h1>
          <Box title={"Agregar"} Content={Agregar} request={request} />
          {editableFields.length > 0 ? (
            <Box title={"Editar"} Content={Editar} request={request} />
          ) : (
            ""
          )}

          <div className="row center box p2010 vcenter m5px cursor wrap">
            <div className="row center vcenter">
              <div className="row left vcenter title_box">
                <h3 className="dark">Registros</h3>
              </div>
            </div>
            <div className="row t_res mt5px">
              {data.length > 0 ? (
                <table className="table">
                  <thead className="t_header">
                    <tr>
                      {Object.keys(data[0]).map((header) => (
                        <th className={header == "editar" ? `row center` : ""}>
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="t_body">
                    {data.map((row) => (
                      <tr>
                        {Object.values(row).map((value) => (
                          <td
                            className={
                              row.editar != null && row.editar == value
                                ? `row center`
                                : ""
                            }
                          >
                            {value.toString().includes("/images") ? (
                              <img
                                className="img_mini"
                                src={`${baseURL}${value}`}
                              />
                            ) : row.editar != null && row.editar == value ? (
                              <div
                                className="row center btn_edit"
                                value={value}
                              >
                                Editar
                              </div>
                            ) : (
                              value
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="row center vcenter">Sin datos</div>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Main;
