import Login from "./components/Login";
import Main from "./components/Main";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { ThreeCircles } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [init, setInit] = useState(true);
  const [user, setUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const baseURL = "https://onexo.mx:8443";
  const axios_instance = axios.create({
    withCredentials: true,
    baseURL: baseURL,
  });

  function request(
    method,
    url_mothod,
    ok_action,
    error_action,
    params,
    message,
    show,
    conf
  ) {
    var id = null;
    if (show) id = toast.loading(message, { toastId: method });
    if (method === "get") {
      axios_instance
        .get(url_mothod, params)
        .then(function (response) {
          toast.update(id, {
            render: response.data.message,
            type: "success",
            isLoading: false,
            autoClose: true,
            toastId: response.data.message,
          });
          ok_action(response, id);
          return response;
        })
        .catch(function (error) {
          toast.update(id, {
            render: `${error.response.status} ${error.response.data.detail.message}`,
            type: "error",
            isLoading: false,
            autoClose: true,
            toastId: `${error.response.status} ${error.response.data.detail.message}`,
          });
          error_action(error);
          return error;
        });
    } else {
      if (params == null) {
        params = new URLSearchParams();
        params.append(
          "public_key",
          "?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L"
        );
      }
      axios_instance
        .post(url_mothod, params, conf)
        .then(function (response) {
          toast.update(id, {
            render: response.data.message,
            type: "success",
            isLoading: false,
            autoClose: true,
            toastId: response.data.message,
          });
          ok_action(response);
        })
        .catch(function (error) {
          console.log(error);
          toast.update(id, {
            render: `${error.response.status} ${error.response.data.detail.message}`,
            type: "error",
            isLoading: false,
            autoClose: true,
            toastId: `${error.response.status} ${error.response.data.detail.message}`,
          });
          error_action(error);
        });
    }
  }

  useEffect(() => {
    if (cookies.sessionid)
      request(
        "post",
        "/check_token",
        (response) => {
          setCookie("sessionid", response.data.token);
          if (user === null) setUser(response.data.user);
          setInit(false);
        },
        (error) => {
          setUser(null);
          setInit(false);
        },
        null,
        "Validando sesion",
        false
      );
    else {
      setInit(false);
    }
  }, []);

  if (init) {
    return (
      <div className="loader row center vcenter">
        <ThreeCircles
          height="70"
          width="70"
          color="#ff6633"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
      </div>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Main
                user={user}
                setUser={setUser}
                removeCookie={removeCookie}
                axios_instance={axios_instance}
                setCookie={setCookie}
                request={request}
                toast={toast}
                baseURL={baseURL}
              />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Login
                user={user}
                setUser={setUser}
                removeCookie={removeCookie}
                axios_instance={axios_instance}
                setCookie={setCookie}
                toast={toast}
                request={request}
              />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
