import Btnmenu from "./Btnmenu"
import logo from "./../../../images/logo.png";
import { Icon } from '@iconify/react';
function Navbar({user, pActive, setPActive,windowDimensions,toggle_menu}){
    return(
        <div className="row navbar box">
          {(windowDimensions.width < 1000)? <div className="menu close" onClick={toggle_menu}>
          <Icon icon="carbon:close" style={{fontSize:30}} />
          </div>:""}
         
          <div className="row center wrap">
            <h3 className="title row center vcenter">
              <img src={logo} alt="onexo" className="logo" />
              Onexo
            </h3>
            {user.rol.menu.map((modulo) => (
             <Btnmenu modulo={modulo} pActive={pActive} setPActive={setPActive} toggle_menu={toggle_menu} />
            ))}
          </div>
        </div>
    )
}

export default Navbar