import { Icon, InlineIcon } from "@iconify/react";

function Btnmenu({modulo, pActive, setPActive,toggle_menu}){
    return(
        <div
        className={`row center item_menu vcenter ${
          modulo == pActive ? "active" : ""
        }`}
        onClick={() => {
          setPActive(modulo);
          toggle_menu()
        }}
      >
        <div className="row left">{modulo.name}</div>
        <div className="row right">
          <Icon
             className={`icon_menu row center ${
              modulo == pActive ? "active" : ""
            }`}
          
            icon={modulo.aplicacion.icon}
          />
        </div>
      </div>
    )
}

export default Btnmenu