import Form from "./snippets/Login/Form";
import onexo_logo from "./../images/logo.png";
import { Navigate } from "react-router-dom";
import "./../App.css";
function Login({ user, setUser, axios_instance, setCookie, toast, request }) {
  if (user) return <Navigate to="/" />;
  return (
    <div className="row center vcenter h100vh">
      <div className="login row center vcenter wrap">
        <div className="row center p20">
          <img src={onexo_logo} alt="Onexo" width={100} />
        </div>
        <div className="row center">
          <h1 className="nunito">ONEXO</h1>
        </div>
        <Form
          setUser={setUser}
          axios_instance={axios_instance}
          setCookie={setCookie}
          toast={toast}
          request={request}
        />
      </div>
    </div>
  );
}
export default Login;
