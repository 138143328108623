import Fade from "react-reveal/Fade";

function Form({ setUser, axios_instance, setCookie, toast, request }) {
  function login() {
    var username = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    if (username.length > 0 && password.length > 0) {
      const params = new URLSearchParams();
      params.append("username", username);
      params.append("password", password);
      params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L")
      request(
        "post",
        "/token",
        (response) => {
          if (response.status == 200) {
            setCookie("sessionid", response.data.access_token);
            setUser(response.data.user);
          }
        },
        (error) => {
          toast.error(error, { toastId: error, theme: "dark" });
        },params, "Validando credenciales",true
      );
    } else {
      toast.error("Debes de llenar todos los campos", {
        toastId: "login",
        theme: "dark",
      });
    }
  }

  return (
    <Fade bottom cascade>
      <div className="row center wrap form">
        <div className="row center wrap group_input">
          <label htmlFor="email" className="row left label_form">
            Usuario
          </label>
          <input
            type="email"
            name="email"
            className="row center input"
            placeholder="Usuario"
            id="email"
          />
        </div>
        <div className="row center wrap group_input">
          <label htmlFor="password" className="row left label_form">
            Contraseña
          </label>
          <input
            type="password"
            name="password"
            className="row center input"
            placeholder="*********"
            id="password"
          />
        </div>

        <div className="row center btn_blue input" onClick={login}>
          Iniciar sesión
        </div>
      </div>
    </Fade>
  );
}

export default Form;
