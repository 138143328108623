import { Icon, InlineIcon } from "@iconify/react";
import useCollapse from "react-collapsed";
import { useState, useEffect } from "react";
function Box({title, Content,request}) {
  const [isExpanded, setExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });
  return (
    <div className="row center box p2010 vcenter m5px cursor wrap">
      <div className="row center vcenter"
        {...getToggleProps({
          style: { display: "flex" },
          onClick: () => {
            setExpanded((x) => !x);
          },
        })}
      >
        <div className="row left vcenter">
          <h3 className="dark">{title}</h3>
        </div>
        <div className={`icon_box ${(isExpanded)?"active":""}`}>
          <Icon className="row center dropw" icon={"ri:arrow-drop-down-line"} />
        </div>
      </div>
      <div className="center row mt5px wrap" {...getCollapseProps()}>
        <Content title={title} />
      </div>
    </div>
  );
}

export default Box;
