
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from "react";
import { useEffect } from "react";
function Formulario({
  pActive,
  fields,
  request,
  baseURL,
  data,
  setData,
  title,
  action,
  setEditableFields,
  setFields,
  windowDimensions
}) {

  const [value, setValue] = useState('');
  useEffect(()=>{
    var input = document.getElementById(`${action.substring(1)}_content`)
    if(input)input.value = value
  },[value] )

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      [{ 'align': [] }],
      ['clean']
      
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  function handleSubmit() {
    const form = document.getElementById(`form_${action}`);
    const formData = new FormData(form);
    formData.append('public_key',"?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L")
    formData.append('private_key',"+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu")
    request(
      "post",
      `${pActive.aplicacion.path}${action}`,
      (response) => {
        if (response.status == 200) {
          setData(response.data.data);
          var array = [];
          var editable_array = [];
          for (let i in response.data.form.fields) {
            if (i % 2 == 0) {
              var subarray = [];
              subarray.push(response.data.form.fields[i]);
              array.push(subarray);
            } else {
              var subarray = array[array.length - 1];
              subarray.push(response.data.form.fields[i]);
              //array.push(subarray)
            }
          }
          for (let i in response.data.editable_form.fields) {
            if (i % 2 == 0) {
              var subarray = [];
              subarray.push(response.data.editable_form.fields[i]);
              editable_array.push(subarray);
            } else {
              var subarray = editable_array[editable_array.length - 1];
              subarray.push(response.data.editable_form.fields[i]);
              //array.push(subarray)
            }
          }
          setFields(array);
          setEditableFields(editable_array);
        }
      },
      (error) => {
        console.log(error);
      },
      formData,
      `Enviando datos`,
      true,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  return (
    <form id={`form_${action}`} className="row center wrap">
      {fields.map((field) => (
        <div className={`row center ${windowDimensions.width<600?"wrap":""}`}>
          {field.map((input) => (
            <div className="row input_group wrap left">
              <label htmlFor={input.name} className="field">
                {input.print_name}:
              </label>
              {input.type == "select" ? (
                <select name={input.name} id={input.name} className="input">
                  {input.values.map((value) => (
                    <option value={value.id}>{value.name}</option>
                  ))}
                </select>
              ) : input.type === "edit"?(
                <div className="row center">
                  <div className="editor" id="editor">
                    <input type="hidden" id={`${action.substring(1)}_content`} name={`${action.substring(1)}_content`} />
                  <ReactQuill theme="snow" value={value} onChange={setValue}  modules={modules}
                    formats={formats} />
                  </div>
                </div>
              ) : (
                <input
                  type={input.type}
                  name={input.name}
                  id={input.name}
                  className="input"
                  accept={input.accept}
                />
              )}
            </div>
          ))}
        </div>
      ))}
      <input
        type="button"
        className="row center btn_blue input"
        value={title}
        onClick={handleSubmit}
      />
      
    </form>
  );
}

export default Formulario;
