import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RingLoader } from "react-spinners";

var state = {
  loading: true,
};

const fakeRequest = () => {
  return new Promise((resolve) => setTimeout(() => resolve(), 10));
};

const root = ReactDOM.createRoot(document.getElementById("root"));

if (state.loading) {
  root.render(
   null
  );
  fakeRequest().then(() => {
    const el = document.querySelector(".loader_container");
    if (el) {
      el.remove(); // removing the spinner element
      state.loading = false;
    }
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
